import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { auth } from '../firebase/firebase';
import useRequestAPI from '../services/useRequestApi';
interface AuthContextProps {
    login: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
    logout: () => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    signup: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
    currentUser: any;
    refreshToken: () => any;
}

export const AuthContext = React.createContext<AuthContextProps>(undefined!);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const { requestAPI } = useRequestAPI();
    const [currentUser, setCurrentUser] = useState<any>({});
    const [isLoadingRefreshToken, setIsLoadingRefreshToken] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);

    const signup = (email: string, password: string): Promise<firebase.auth.UserCredential> => {
        return requestAPI('POST', '/user', { email, password });
    };

    const login = (email: string, password: string): Promise<firebase.auth.UserCredential> => {
        return auth.signInWithEmailAndPassword(email, password);
    };

    const logout = (): Promise<void> => {
        return auth.signOut();
    };

    const resetPassword = (email: string): Promise<void> => {
        return auth.sendPasswordResetEmail(email);
    };

    const refreshToken = () => {
        if (isLoadingRefreshToken) return;
        setIsLoadingRefreshToken(true);
        return auth?.currentUser?.getIdToken(true).then((token) => {
            setCurrentUser((oldUser) => {
                const newUser = oldUser;
                newUser.user.Aa = token;
                return newUser;
            })
            setIsLoadingRefreshToken(false);

        }).catch(() => {
            setIsLoadingRefreshToken(false);
            logout()
        });
    }
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser((currentUser) => ({ ...currentUser, user }));
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        login,
        signup,
        logout,
        resetPassword,
        refreshToken
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
