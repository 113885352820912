import React, { FC, useState, useMemo, ReactNode, useCallback } from 'react';

// create context
export const SidebarContext = React.createContext<SidebarContextProps>({
    isSidebarOpen: false,
    closeSidebar: () => '',
    toggleSidebar: () => '',
});

interface Props {
    children: ReactNode;
}

interface SidebarContextProps {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;
}

export const SidebarProvider: FC<Props> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = useCallback(() => {
        setIsSidebarOpen(!isSidebarOpen);
    }, [isSidebarOpen]);

    const closeSidebar = useCallback(() => {
        setIsSidebarOpen(false);
    }, []);

    const value = useMemo<SidebarContextProps>(() => {
        return {
            isSidebarOpen,
            toggleSidebar,
            closeSidebar,
        };
    }, [isSidebarOpen, closeSidebar, toggleSidebar]);

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
