import React from 'react';
import Loader from './Loader/Loader';

function ThemedSuspense() {
    return (
        <div className='w-full h-full'>
            <Loader />
        </div>
        // <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
        //     Loading...
        // </div>
    );
}

export default ThemedSuspense;
