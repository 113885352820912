import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const CreateAccount = lazy(() => import('./pages/CreateAccount'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));

const RedirectComponent = () => {
    React.useEffect(() => {
        window.open('https://bascule-shoptracker.web.app/');
        window.location.href = '/app';
    }, []);

    return null;
};

function App() {
    return (
        <Router>
            <AccessibleNavigationAnnouncer />
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/create-account" component={CreateAccount} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/bascule-shoptracker" component={RedirectComponent} />

                {/* Place new routes over this */}
                <PrivateRoute path="/app" component={Layout} />
                {/* If you have an index page, you can remothis Redirect */}
                <Redirect exact from="/" to="/login" />
            </Switch>
        </Router>
    );
}

export default App;
