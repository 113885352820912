import React, { useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

interface ISendRequestProps {
    method: string
    url: string
    data
    configs
    service: string
}

const useRequestAPI = () => {
    const currentUser = useAuth();

    const sendRequest = useCallback(
        async ({ method, url, data, configs, service}: ISendRequestProps ) => {

            const services = {
                api: process.env.REACT_APP_BASCULE_API,
                csv: process.env.REACT_APP_BASCULE_CSV
            }

            const getService = (service: string): string => {
                if (!services[service]) throw new Error("Bad service instanciate");
                return services[service];
            }

            const rootURL = getService(service)
            const request = selectMethodRequest(method);
            let res;
            const URL = rootURL + url;

            if (method === 'GET' || method === 'DELETE') {
                res = await request(URL, {
                    headers: {
                        Authorization: `Bearer ${currentUser.currentUser.user.Aa}`,
                        ...configs,
                    },
                });
            } else {
                if (currentUser?.currentUser?.user) {
                    res = await request(URL, data, {
                        headers: {
                            Authorization: `Bearer ${currentUser.currentUser.user.Aa}`,
                            ...configs,
                        },
                    });
                } else {
                    res = await request(URL, data);
                }
            }

            return res.data;
        },
        [currentUser],
    )

    const requestAPI = useCallback(
        async (method, url, data?, configs?) => {
            return await sendRequest({method, url, data, configs, service: 'api'})
        }, [sendRequest]
    );

    const requestCSVAPI = useCallback(
        async (method, url, data?, configs?) => await sendRequest({method, url, data, configs, service: 'csv'}), [sendRequest]
    );

    return { requestAPI, requestCSVAPI };
};

const selectMethodRequest = (method: string) => {
    const methodRequest = {
        GET: axios.get,
        POST: axios.post,
        PUT: axios.put,
        PATCH: axios.patch,
        DELETE: axios.delete,
    };

    return methodRequest[method];
};

export default useRequestAPI;
