import React, { Suspense, useContext } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import { ParametersContext, ParametersProvider } from './context/ParametersContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import * as serviceWorker from './serviceWorker';
import myTheme from './myTheme';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SWRConfig } from 'swr';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';

Chart.register(ChartDataLabels);

const Application = () => {
    const authContext = useAuth()
    return (
            <SWRConfig value={{
                onError: (error, key) => {
                    if (error.status !== 401 && error.status !== 403) {
                        authContext.refreshToken();
                    }
                },
                revalidateIfStale: false,
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
                errorRetryInterval: 2000
            }}>
                <SidebarProvider>
                    <ParametersProvider>
                        <Suspense fallback={<ThemedSuspense />}>
                            <Windmill usePreferences theme={myTheme}>
                                <App />
                            </Windmill>
                        </Suspense>
                    </ParametersProvider>
                </SidebarProvider>
            </SWRConfig>)
}
ReactDOM.render(
    <ThemeProvider>
        <AuthProvider>
            <Application/>
        </AuthProvider>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(null);
