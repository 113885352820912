import React, { FC, createContext, useState, ReactNode } from 'react';
import { stringify } from 'query-string';

interface State<T> {
    value: T;
    set: React.Dispatch<React.SetStateAction<T>>;
}

enum ETypeOfWithdrawal {
    ALL = 'all',
    DRIVE = 'drive',
    DELIVERY = 'delivery',
}

interface ParametersContextProps {
    StartDate: State<string>;
    EndDate: State<string>;
    Enseignes: State<string[]>;
    Categories: State<string[]>;
    SelectionSpecifique: State<string[]>;
    Marques: State<string[]>;
    Bio: State<boolean>;
    Params: string;
    TypeOfWithdrawal: State<ETypeOfWithdrawal>;
}

export const ParametersContext = createContext<ParametersContextProps>(undefined!);

interface Props {
    children: ReactNode;
}

const default12monthStartDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 12);
    return date.toISOString().split('T')[0];
}


export const ParametersProvider: FC<Props> = ({ children }) => {
    const [StartDate, setStartDate] = useState(default12monthStartDate());
    const [EndDate, setEndDate] = useState('');
    const [Enseignes, setEnseignes] = useState<string[]>([]);
    const [Categories, setCategories] = useState<string[]>([]);
    const [SelectionSpecifique, setSelectionSpecifique] = useState<string[]>([]); // TODO: [SelectionSpecifique, setSelectionSpecifique
    const [Marques, setMarques] = useState<string[]>([]);
    const [Bio, setBio] = useState(false);
    const [TypeOfWithdrawal, setTypeOfWithdrawal] = useState<ETypeOfWithdrawal>(ETypeOfWithdrawal.ALL)

    const createParams = () => {
        const chainParams: any = {};

        if (StartDate !== '') chainParams.startDate = StartDate.replaceAll('-', '');
        if (EndDate !== '') chainParams.endDate = EndDate.replaceAll('-', '');
        if (TypeOfWithdrawal !== ETypeOfWithdrawal.ALL) chainParams.typeOfWithdrawal = TypeOfWithdrawal;
        if (Enseignes.length !== 0) chainParams.enseignes = Enseignes;
        if (Marques.length !== 0) chainParams.brands = Marques;
        if (Categories.length !== 0 || SelectionSpecifique.length !== 0) chainParams.marketsFamily = [...Categories, ...SelectionSpecifique];
        if (Bio === true) chainParams.bio = true;

        return stringify(chainParams, { arrayFormat: 'bracket' });
    };

    return (
        <ParametersContext.Provider
            value={{
                StartDate: { value: StartDate, set: setStartDate },
                EndDate: { value: EndDate, set: setEndDate },
                Enseignes: { value: Enseignes, set: setEnseignes },
                Categories: { value: Categories, set: setCategories },
                SelectionSpecifique: { value: SelectionSpecifique, set: setSelectionSpecifique },
                Marques: { value: Marques, set: setMarques },
                Bio: { value: Bio, set: setBio },
                TypeOfWithdrawal: { value: TypeOfWithdrawal, set: setTypeOfWithdrawal },
                Params: createParams(),
            }}
        >
            {children}
        </ParametersContext.Provider>
    );
};
