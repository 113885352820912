const myTheme = {
    pagination: {
        base: 'flex flex-col justify-between text-xs sm:flex-row text-gray-600 dark:text-gray-400',
    },
    tableFooter: {
        base: 'px-4 py-3 border-t dark:border-gray-700 bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-800',
    },
    tableRow: {
        base: '',
    },
    tableHeader: {
        base:
            'text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800',
    },
    tableContainer: {
        base: 'w-full overflow-hidden rounded-lg shadow-xs',
    },
    tableCell: {
        base: 'px-4 py-3',
    },
    tableBody: {
        base: 'bg-white divide-y dark:divide-gray-700 dark:bg-gray-800 text-gray-700 dark:text-gray-400',
    },
    dropdownItem: {
        base: 'mb-2 last:mb-0',
    },
    dropdown: {
        base:
            'absolute w-56 p-2 mt-2 text-gray-600 bg-white border border-gray-100 rounded-lg shadow-md min-w-max-content dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700',
        align: {
            left: 'left-0',
            right: 'right-0',
        },
    },
    avatar: {
        base: 'relative rounded-full inline-block',
        size: {
            large: 'w-10 h-10',
            regular: 'w-8 h-8',
            small: 'w-6 h-6',
        },
    },
    modal: {
        base:
            'w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl',
    },
    modalBody: {
        base: 'mb-6 text-sm text-gray-700 dark:text-gray-400',
    },
    modalFooter: {
        base:
            'flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800',
    },
    modalHeader: {
        base: 'mt-4 mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300',
    },
    badge: {
        base: 'inline-flex px-2 text-xs font-medium leading-5 rounded-full',
        success: 'text-green-700 bg-green-100 dark:bg-green-700 dark:text-green-100',
        danger: 'text-red-700 bg-red-100 dark:text-red-100 dark:bg-red-700',
        warning: 'text-blue-700 bg-blue-100 dark:text-white dark:bg-blue-600',
        neutral: 'text-gray-700 bg-gray-100 dark:text-gray-100 dark:bg-gray-700',
        primary: 'text-blue-700 bg-blue-100 dark:text-white dark:bg-blue-600',
    },
    backdrop: {
        base: 'fixed inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center',
    },
    textarea: {
        base: 'block w-full text-sm dark:text-gray-300 form-textarea focus:outline-none',
        active:
            'focus:border-blue-400 dark:border-gray-600 dark:focus:border-gray-600 dark:bg-gray-700 dark:focus:shadow-outline-gray focus:shadow-outline-blue',
        disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
        valid:
            'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:shadow-outline-green dark:focus:shadow-outline-green',
        invalid:
            'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:shadow-outline-red dark:focus:shadow-outline-red',
    },
    select: {
        base: 'block w-full text-sm dark:text-gray-300 focus:outline-none',
        active:
            'focus:border-blue-400 dark:border-gray-600 dark:bg-gray-700 focus:shadow-outline-blue dark:focus:shadow-outline-gray dark:focus:border-gray-600',
        select: 'form-select leading-5',
        multiple: 'form-multiselect',
        disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
        valid:
            'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:shadow-outline-green dark:focus:shadow-outline-green',
        invalid:
            'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:shadow-outline-red dark:focus:shadow-outline-red',
    },
    label: {
        base: 'block text-sm text-gray-700 dark:text-gray-400',
        check: 'inline-flex items-center',
        disabled: 'opacity-50 cursor-not-allowed',
    },
    input: {
        base: 'block w-full text-sm focus:outline-none dark:text-gray-300 form-input leading-5',
        active:
            'focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700',
        disabled: 'cursor-not-allowed opacity-50 bg-gray-300 dark:bg-gray-800',
        valid:
            'border-green-600 dark:bg-gray-700 focus:border-green-400 dark:focus:border-green-400 focus:shadow-outline-green dark:focus:shadow-outline-green',
        invalid:
            'border-red-600 dark:bg-gray-700 focus:border-red-400 dark:focus:border-red-400 focus:shadow-outline-red dark:focus:shadow-outline-red',
        radio:
            'text-blue-600 form-radio focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:focus:shadow-outline-gray',
        checkbox:
            'text-blue-600 form-checkbox focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:focus:shadow-outline-gray',
    },
    helperText: {
        base: 'text-xs',
        valid: 'text-green-600 dark:text-green-400',
        invalid: 'text-red-600 dark:text-red-400',
    },
    card: {
        base: 'min-w-0 rounded-lg shadow-md overflow-hidden',
        default: 'bg-white dark:bg-gray-700',
    },
    cardBody: {
        base: 'p-4',
    },
    button: {
        base:
            'align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none',
        block: 'w-full',
        size: {
            larger: 'px-10 py-4 rounded-lg',
            large: 'px-5 py-3 rounded-lg',
            regular: 'px-4 py-2 rounded-lg text-sm',
            small: 'px-3 py-1 rounded-md text-sm',
            icon: {
                larger: 'p-4 rounded-lg',
                large: 'p-3 rounded-lg',
                regular: 'p-2 rounded-lg',
                small: 'p-2 rounded-md',
            },
            pagination: 'px-3 py-1 rounded-md text-xs',
        },
        icon: {
            larger: 'h-5 w-5',
            large: 'h-5 w-5',
            regular: 'h-5 w-5',
            small: 'h-3 w-3',
            left: 'mr-2 -ml-1',
            right: 'ml-2 -mr-1',
        },
        primary: {
            base: 'text-white bg-blue-600 border border-transparent',
            active: 'active:bg-blue-600 hover:bg-blue-700 focus:shadow-outline-blue',
            disabled: 'opacity-50 cursor-not-allowed',
        },
        generic: {
            base: 'text-white bg-white-600 border border-transparent',
            active: 'active:bg-white-600 hover:bg-white-700 focus:shadow-outline-blue',
            disabled: 'opacity-50 cursor-not-allowed',
        },
        outline: {
            base: 'text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none',
            active:
                'active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:shadow-outline-gray',
            disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
        },
        link: {
            base: 'text-gray-600 dark:text-gray-400 focus:outline-none border border-transparent',
            active:
                'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray dark:hover:bg-gray-500 dark:hover:text-gray-300 dark:hover:bg-opacity-10',
            disabled: 'opacity-50 cursor-not-allowed',
        },
        dropdownItem: {
            base:
                'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200',
        },
    },
};

export default myTheme;
